import React, { Component } from 'react';
import { withRouter } from '../../hoc/withRouter';
import qs from 'query-string';
import { URL, DEFAULT_PAGINATION_SIZE } from '../../config';
import Breadcrumb from '../../components/breadcrumb';
import ProductCards from '../../components/productcards';
import CompareBar from '../../components/comparebar';
import './styles.scss';


class CategoryLevel4 extends Component {
    productCardComponents = [];
    state = {
        isComparable: false,
        isChanging: false,
        isGridView: true,
        isFirstFetch: true,
        isLoading: false,
        isLoadingPage: false,
        page: 1,
        results: {
            TotalRecords: 0,
            SearchResults: []
        }
    };

    static async getFacets(category1, category2, category3) {
        try {
          const res = await fetch(`${URL}/api/v1/search/categories/${category1}/${category2}/${category3}`, {
          method: 'GET'
          });
    
          return res.json();
        } catch (err) {
            const err500 = new Error(err);
            err500.status = 500;
            throw err500;
        }
      }
    
    static async requestInitialData(routes, req, res, cache) {
        //Get search
        let facets = cache && cache.get(`SEARCH_${req.originalUrl}`);
        
        if (!facets) {
          const { params } = routes;
          facets = await CategoryLevel4.getFacets(params.category1, params.category2, params.category3);
          if (cache) {
            cache.set(`SEARCH_${req.originalUrl}`, facets, 7200);
          }
        }

        const lowestLevel = facets.Facets && facets.Facets.filter(f => f.Key === 'varugruppbenamning')[0].FacetValues[0];
        
        return {
          search: {
            ...facets,
            title: lowestLevel.Label
          }
        };    
    }

    handleCompareChange() {
        let comparableProducts = 0;
        let isComparable = false
        this.productCardComponents.forEach((product) => {
            if (product.state.isChecked) {
                comparableProducts++;
                
            }
        });

        if (comparableProducts > 1) {
            isComparable = true;
        }

        if (isComparable !== this.state.isComparable) {
            this.setState({
                isComparable
            });
        }
    }

    async fetchSearchResults(query, params) {

        const q = qs.stringify({
            FromRange: query.page ? (query.page-1) * DEFAULT_PAGINATION_SIZE : 0,
            varuomradebenamning: this.getTopLevel() && this.getTopLevel().Label,
            huvudgruppbenamning: this.getMiddleLevel() && this.getMiddleLevel().Label
        });

        const resResult = await fetch(`/api/v1/search?${q}`, {
            method: 'GET'
        });

        //Emit search results
        return await resResult.json();
    }

    getTopLevel() {
        const { search } = this.props.initialData;
        if (!search) return null;

        return search.Facets && search.Facets.filter(f => f.Key === 'varuomradebenamning')[0].FacetValues[0];
    }

    getMiddleLevel() {
        const { search } = this.props.initialData;
        if (!search) return null;

        return search.Facets && search.Facets.filter(f => f.Key === 'huvudgruppbenamning')[0].FacetValues[0];
    }

    getLowestLevel() {
        const { search } = this.props.initialData;
        if (!search) return null;

        return search.Facets && search.Facets.filter(f => f.Key === 'varugruppbenamning')[0].FacetValues[0];
    }

    async fetchSearchResults(query) {
        const q = qs.stringify({
            FromRange: query.page ? (query.page-1) * DEFAULT_PAGINATION_SIZE : 0,
            varuomradebenamning: this.getTopLevel() && this.getTopLevel().Label,
            huvudgruppbenamning: this.getMiddleLevel() && this.getMiddleLevel().Label,
            varugruppbenamning: this.getLowestLevel() && this.getLowestLevel().Label
        });
        
        const resResult = await fetch(`/api/v1/search?${q}`, {
            method: 'GET'
        });

        //Emit search results
        return await resResult.json();
    }

    async componentDidMount() {
        const query = qs.parse(this.props.location.search);

        const { ...results } = await this.fetchSearchResults(query);
        this.setState({
            results,
            isFirstFetch: false
        });
    }

    async componentDidUpdate(prevProps) {
        const prevQuery = qs.parse(prevProps.location.search);
        const currentQuery = qs.parse(this.props.location.search);

        if (currentQuery.page && prevQuery.page !== currentQuery.page) {
            
            this.setState({
                isLoadingPage: true
            });
            
            const results = await this.fetchSearchResults(currentQuery);
            this.setState({
                results: {
                    ...this.state.results,
                    SearchResults: [...this.state.results.SearchResults, ...results.SearchResults]
                },
                isLoadingPage: false
            });

            return;
        }
    }

    addProductcardComponent(component) {
        this.productCardComponents.push(component);
    }

    render() {

        const lowestLevel = this.getLowestLevel();
        const middleLevel = this.getMiddleLevel();
        const topLevel = this.getTopLevel();

        if (!lowestLevel) return null;

        const { location } = this.props;
        const upOneLevel = location.pathname.replace(/\/?$/, '/');

        return (
            <div>
                <Breadcrumb modifiers={{ 'breadcrumb--gray': true }}>
                    <ul className="breadcrumb__list">
                        <li><a className="breadcrumb__listitem" href={`${upOneLevel}../../../`}><span>Kategorier</span></a></li> 
                       <li><a className="breadcrumb__listitem" href={`${upOneLevel}../../`}><span>{topLevel.Label}</span></a></li> 
                       <li><a className="breadcrumb__listitem" href={`${upOneLevel}../`}><span>{middleLevel.Label}</span></a></li> 
                       <li><a className="breadcrumb__listitem breadcrumb__listitem--selected" href="."><span>{lowestLevel.Label}</span></a></li>
                    </ul>
                </Breadcrumb>
    
                <section className="product-cards explore-products sub-page gray-background">
                    <div className="explore-products__fit-content">
                        <div className="explore-products__content-padding">
                            <h1>{lowestLevel.Label}</h1>
                            <span className="product-count">{lowestLevel.Count} produkter</span>
                        </div>
                    </div>

                    <form method="get" action="/search/compare">
                        <ProductCards compare={true}
                                    useSlider={false}
                                    search={this.state.results}
                                    isGridView={this.state.isGridView} 
                                    isLoading={this.state.isLoading}
                                    isLoadingPage={this.state.isLoadingPage}
                                    addProductcardComponent={(component) => this.addProductcardComponent(component)} 
                                    handleCompareChange={() => this.handleCompareChange()}
                                    modifiers={{
                                        'product-cards--search-page': true,
                                        'product-cards--gray-background': true,
                                        'product-cards--is-filtering': this.state.isLoading,
                                        'product-cards--is-loading': this.state.isLoadingPage,
                                        'product-cards--is-firstfetch': this.state.isFirstFetch

                                    }} />
                        <CompareBar isComparable={this.state.isComparable} />
                    </form>
                </section>
            </div>
        );
    }
};

export default withRouter(CategoryLevel4);