import React, { Component } from 'react';
import classNames from 'classnames';
import Komponent from './komponent';
import Nettoinnehall from './nettoinnehall';
import { format } from "date-fns";

const Property = ({prop, text}) => {
    if (!prop) return null;
    
    return (
        <tr>
            <th>{text}</th>
            <td>{prop}</td>
        </tr>
    );
};

const PropertyRepetable = ({prop, nameProp, valueProp, text}) => {
    if (!prop) return null;
    
    if (prop && prop.length === 1) {
        return (
            <tr>
                <th>{text}</th>
                <td>
                    {prop.map((item, i) => <React.Fragment key={`${text}${i}`}>{valueProp ? `${item[valueProp]} ` : ''}{item[nameProp]}</React.Fragment>)}
                </td>
            </tr>
        );
    }
    
    if (prop && prop.length > 1) {
        return (
            <tr>
                <th>{text}</th>
                <td>
                    <ul>
                        {prop.map((item, i) => <li key={`${text}${i}`}>{valueProp ? `${item[valueProp]} ` : ''}{item[nameProp]}</li>)}
                    </ul>
                </td>
            </tr>
        );
    }

    return null;
};

const PropertyBoolean = ({prop, text}) => {
    if (prop === false) {
        return <Property prop="Nej" text={text} />
    }

    if (prop === true) {
        return <Property prop="Ja" text={text} />
    }

    return null;
};

const PropertyNumber = ({prop, text}) => {
    if (prop && prop !== null && prop !== undefined) {
        return (
            <tr>
                <th>{text}</th>
                <td>{prop}</td>
            </tr>
        );
    }

    return null;
};


const Mattkvalificerarebasmangd = (naringsinfo) => {
    if (!naringsinfo.Mattkvalificerarebasmangd) return null;

    switch (naringsinfo.Mattkvalificerarebasmangd) {
        case 'Gram': 
            return 'gr';
        case 'Milliliter':
            return 'ml';
        default: 
            return null;
    }
}

const enhetText = (kod) => {
    switch (kod) {
        case 'YEAR': 
            return 'år';
        case 'CMT':
            return 'cm';            
        case 'KGM':
            return 'kg';
        case 'GRM':
            return 'g';
        case 'MLT':
            return 'ml';
        default:
            return 'år';
    }
}

const staplingsbarhet = kod => {
    switch (kod) {
        case 'STORAGE_UNSPECIFIED': 
            return 'Staplingsbarhet ospecificerad lagring';
        case 'TRANSPORT_UNSPECIFIED':
            return 'Staplingsbarhet ospecificerad transport	';            
        case 'UNSPECIFIED':
            return 'Staplingsbarhet, ospecificerad	';
        default:
            return '';
    }
}

class Bas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    handleToggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    /*hasElementValues(moduleId, elementId) {
        
        const product = this.props.KomplettArtikel;
        const module = product.Modules.find(f => f.ModuleId === moduleId);
        if (module) {
            const element = module.Elements.filter(f => f.ElementId === elementId);
            console.log(element);
            return element && element.Values.length > 0;
        }

        return false;
    }

    elementValues(moduleId, elementId) {
        const product = this.props.KomplettArtikel;
        const module = product.Modules.find(f => f.ModuleId === moduleId);
        if (module) {
            

            const element = module.Elements.filter(f => f.ElementId === elementId);
            if (element) {
                return element.Values;
            }
            return null;
        }

        return null;
    }*/

    createModules(modules) {
        const moduleKeys = [];
            
        modules.forEach(module => {
            module.Elements.forEach(element => {
                if (element.ValueSets && element.ValueSets.length > 0) {
                    moduleKeys[`${module.ModuleId}/${element.ElementId}`] = {
                        ...element
                    }
                } 
            });
        });

        return moduleKeys;
    }

    render() {
        const product = this.props.KomplettArtikel;
        const numOfitems = this.props.AntalEnheter;
       
        if (!product) return null;

        const modules = this.createModules(product.Modules);

        return (
            <>
                {product.Komponenter && product.Komponenter.map(komponent =>  <Komponent key={komponent.Komponentensidentitet} {...komponent} Property={(props) => Property(props) } />)}
                <section className={classNames({
                    'product-sheet-box': true,
                    'product-sheet-box--wide': true,
                    'product-sheet-box--collapsed': !this.state.isOpen
                })}>
                    <div className="product-sheet-box__inner">
                        <h2 onClick={() => this.handleToggle()}
                            className="product-sheet-box__heading"><button className="product-sheet-box__heading-toggle">{this.props.heading}<span className="product-sheet-box__heading-gtin"> • GTIN {product.GTIN}</span></button></h2>
                        <div className="product-sheet-box__content">
                            <div className="product-sheet-box__tablecontent">
                                <h3 className="product-sheet-box__tableheading">Artikelidentitet</h3>
                                <table className="product-sheet__table product-sheet__table--striped">
                                    <tbody>
                                        <tr>
                                            <th>Artikelns identitet, GTIN:</th>
                                            <td>{product.GTIN}</td>
                                        </tr>
                                        {product.MalmarknadsomradeText &&
                                            <tr>
                                                <th>Målmarknadsområde:</th>
                                                <td>{product.MalmarknadsomradeText}</td>
                                            </tr>
                                        }
                                        {/*product.Malmarknadsomrade &&
                                            <tr>
                                                <th>Målmarknadsområde:</th>
                                                <td>{product.Malmarknadsomrade}</td>
                                            </tr>
                                        */}
                                        {product.T3495_Artikelavisering &&
                                            <tr>
                                                <th>Artikelavisering:</th>
                                                <td>{product.T3495_Artikelavisering}</td>
                                            </tr>
                                        }
                                        {product.TillverkarensArtikelnummer &&
                                            <tr>
                                                <th>Leverantörens artikelnummer:</th>
                                                <td>{product.TillverkarensArtikelnummer}</td>
                                            </tr>
                                        }
                                        {product.Konsumentartikel !== null &&
                                            <tr>
                                                <th>Konsumentartikel:</th>
                                                <td>{product.Konsumentartikel ? 'Ja' : 'Nej'}</td>
                                            </tr>
                                        }
                                        {product.LogiskEnhet !== null &&
                                            <tr>
                                                <th>Är artikeln en logistisk enhet:</th>
                                                <td>{product.LogiskEnhet ? 'Ja' : 'Nej'}</td>
                                            </tr>
                                        }
                                        {product.FakturerbarEnhet !== null && 
                                            <tr>
                                                <th>Fakturerbar enhet, indikator</th>
                                                <td>{product.FakturerbarEnhet ? 'Ja' : 'Nej'}</td>
                                            </tr>
                                        }
                                        {product.BestallningsbarForpackning !== null &&
                                            <tr>
                                                <th>Beställningsbar artikel, indikator</th>
                                                <td>{product.BestallningsbarForpackning ? 'Ja' : 'Nej'}</td>
                                            </tr>
                                        }
                                        {this.props.consists && this.props.consists.length > 0 &&
                                            <tr>
                                                <th>Består av:</th>
                                                <td>
                                                    {this.props.consists.map((consist, i) => {
                                                        return (
                                                            <React.Fragment key={`consist${i}`}>
                                                                <span>{consist.text}</span><br />
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </td>
                                            </tr>
                                        }

                                        <Property prop={product.Engångsköp && product.Engångsköp.T4332_Namn} text="Är artikeln ett engångsköp:" />


                                        {this.props.includes && this.props.includes.length > 0 &&
                                            <tr>
                                                <th>Ingår i:</th>
                                                <td>
                                                    {this.props.includes.map((include, i) => {
                                                        return (
                                                            <React.Fragment key={`includes${i}`}>
                                                                <span>{include.text}</span><br />
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
        
                            <div className="product-sheet-box__tablecontent">
                                <h3 className="product-sheet-box__tableheading">Beskrivning</h3>
                                <table className="product-sheet__table product-sheet__table--striped">
                                    <tbody>
                                        {product.Artikelkategori &&
                                            <tr>
                                                <th>Artikelkategori:</th>
                                                <td>{product.Artikelkategori}</td>
                                            </tr>
                                        }
            
                                        {product.Artikelbeskrivning &&
                                            <tr>
                                                <th>Artikelbeskrivning:</th>
                                                <td>{product.Artikelbeskrivning}</td>
                                            </tr>
                                        }
            
                                        <Property prop={product.Produktnamn} text="Produktnamn:" />
                                        
                                        {product.Varumarke && product.Varumarke.Varumarke &&
                                            <tr>
                                                <th>Varumärke:</th>
                                                <td>{product.Varumarke.Varumarke}</td>
                                            </tr>
                                        }
                                        
                                        <Property prop={product.Undervarumarke} text="Undervarumärke:" />
            
                                        <Property prop={product.RegleratProduktnamn} text="Reglerat produktnamn:" />
            
                                        <Property prop={product.Storlek} text="Förpackningsstorlek:" />

                                        {modules['TradeItemIdentities/tradeItemDescriptionInformation/variantDescription'] && modules['TradeItemIdentities/tradeItemDescriptionInformation/variantDescription'].ValueSets.map((variantDescriptionValueSet, i) => {
                                            return variantDescriptionValueSet.map(variantDescription => {
                                                return (
                                                    <React.Fragment key={`variantDescription${i}`}>
                                                        <Property prop={variantDescription.Value} text="Variantbeskrivning:" />
                                                    </React.Fragment>
                                                );
                                            });
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            
                            {(product.KompletterandeArtikelId && product.KompletterandeArtikelId.length > 0) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">Kompletterande artikelidentitet</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            {product.KompletterandeArtikelId.map((artId, i) => {
                                                return (
                                                    <React.Fragment key={`artId${i}`}>
                                                        <Property prop={artId.Id} text={artId.Typ} />
                                                    </React.Fragment>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            }
                            
        
                            {(product.ReferenserTillAndraArtiklar && product.ReferenserTillAndraArtiklar.length > 0) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">Referens till annan artikel</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            {product.ReferenserTillAndraArtiklar.map((ref, i) => {
                                                return (
                                                    <React.Fragment key={`refart${i}`}>
                                                        {ref.GTIN &&
                                                            <tr>
                                                                <th>Refererad artikel GTIN:</th>
                                                                <td>{ref.GTIN}</td>
                                                            </tr>
                                                        }
                                                        {ref.Typkod &&
                                                            <tr>
                                                                <th>Typ av referenskod:</th>
                                                                <td>
                                                                    {ref.Typkod === 'REPLACED' && 'Ersatt artikel'}
                                                                    {ref.Typkod === 'ITEM_VARIANT_MASTER' && 'Variantgrundande artikel'}
                                                                </td>
                                                            </tr>
                                                        }
                                                    </React.Fragment>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            }
        
                            {(product.Kontakter && product.Kontakter.length > 0) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">Kontakt</h3>
                                    {product.Kontakter.map((kontakt, i) => {
                                        return (
                                            <table key={`kontakt${i}`} className="product-sheet__table product-sheet__table--striped">
                                                <tbody>
                                                    <Property prop={kontakt.GLN} text="Kontakt GLN:" />
                                                    <Property prop={kontakt.Namn} text="Kontaktnamn:" />
                                                    <Property prop={kontakt.Adress} text="Kontaktadress:" />
                                                    <Property prop={kontakt.Kontakttyp} text="Typ av kontakt:" />
                                                    {kontakt.Kommunikationskanaler && kontakt.Kommunikationskanaler.length > 0 && kontakt.Kommunikationskanaler.map((kanal, x) => {
                                                        return (
                                                            <Property key={`${kanal.KommunikationskanalKod}${x}${i}`} prop={kanal.KommunikationskanalReferens} text={`${kanal.KommunikationskanalKodText}:`} />
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        );
                                    })}
                            </div>
                            }
        
                            {(product.Parter && product.Parter.length > 0) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">Övriga parter</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            {product.Parter.map((part, i) => {
                                                return (
                                                    <React.Fragment key={`part${i}`}>
                                                        <Property prop={part.GLN} text="Partens GLN:" />
                                                        <Property prop={part.Namn} text="Partensnamn:" />
                                                        <Property prop={part.Roll} text="Typ av part:" />
                                                    </React.Fragment>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            }
                            
        
                            {(product.T4204_SökbegreppEhandel ||
                            product.InformationOvrig) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">Marknadsinformation</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            {product.T4204_SökbegreppEhandel &&
                                                <tr>
                                                    <th>Sökbegrepp E-handel</th>
                                                    <td>{product.T4204_SökbegreppEhandel}</td>
                                                </tr>
                                            }
                                            {product.InformationOvrig &&
                                                <tr>
                                                    <th>Övrig Information</th>
                                                    <td>{product.InformationOvrig}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }
        
                            {(product.T4331_Kön || (product.Malgruppskriterium && product.Malgruppskriterium.length > 0)) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">Målgrupp</h3>
                                    {product.T4331_Kön &&
                                        <table className="product-sheet__table product-sheet__table--striped">
                                            <tbody>
                                                <Property prop={product.T4331_Kön.T4331_Namn} text="Målgrupp kön:" />
                                            </tbody>
                                        </table>
                                    }
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            {product.Malgruppskriterium.map((mkriterium, i) => {
                                               return (
                                                    <React.Fragment key={`mkriterium${i}`}>
                                                        <Property prop={`${mkriterium.Min}-${mkriterium.Max} ${enhetText(mkriterium.Enhet)}`} text={mkriterium.Text} />
                                                    </React.Fragment>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            }
        
                            {((product.Marknadsbudskap && product.Marknadsbudskap.length > 0) ||
                            (product.KortMarknadsbudskap && product.KortMarknadsbudskap.length > 0)) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">Marknadsbudskap</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            {product.Marknadsbudskap && product.Marknadsbudskap.length > 0 &&
                                                <tr>
                                                    <th>Marknadsbudskap:</th>
                                                    <td>
                                                        {product.Marknadsbudskap.map((budskap, i) => <p key={`marknadsbudskap${i}`}>{budskap.MarknadsbudskapText}</p>)}
                                                    </td>
                                                </tr>
                                            }
                                            {product.KortMarknadsbudskap && product.KortMarknadsbudskap.length > 0 &&
                                                <tr>                                
                                                    <th>Kort marknadsbudskap:</th>
                                                    <td>
                                                        {product.KortMarknadsbudskap.map((budskap, i) => <p key={`marknadsbudskapkort${i}`}>{budskap.KortMarknadsbudskapText}</p>)}
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }

                            {(product.TypAvKvalitet && product.TypAvKvalitet.length > 0) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">KVALITET</h3>
                                    <table className="product-sheet__table product-sheet__table--column product-sheet__table--striped">
                                        <thead>
                                            <tr>
                                                <th>Typ av kvalitet</th>
                                                <th>Kvalitetsklassificeringssystem</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {product.TypAvKvalitet.map((kvalitet, i) => {
                                                return (
                                                    <tr  key={`kvalitet${i}`}>
                                                        <td>{kvalitet.T4327_Namn}</td>
                                                        <td>{kvalitet.T4328_Namn}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            }
        
                            {product.GiltigFROM &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">Artikelinformationsdatum</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            <tr>
                                                <th>Dokumentets giltighetstidpunkt:</th>
                                                <td>{format(new Date(product.GiltigFROM),'yyyy-MM-dd')}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            }
        
                            {(product.T3742_ForstaLeveransdatum ||
                            product.Lanseringstidpunkt ||
                            product.Tillganglighetstidpunkt) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">Leverans och köp:</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            {product.Tillganglighetstidpunkt &&
                                                <tr>
                                                    <th>Tillgänglighetstidpunkt:</th>
                                                    <td>{format(new Date(product.Tillganglighetstidpunkt),'yyyy-MM-dd')}</td>
                                                </tr>
                                            }
                                            {product.T3742_ForstaLeveransdatum &&
                                                <tr>
                                                    <th>Första leveranstidpunkt:</th>
                                                    <td>{format(new Date(product.T3742_ForstaLeveransdatum),'yyyy-MM-dd')}</td>
                                                </tr>
                                            }
                                            {product.Lanseringstidpunkt &&
                                                <tr>
                                                    <th>Artikel lanseringstidpunkt:</th>
                                                    <td>{format(new Date(product.Lanseringstidpunkt),'yyyy-MM-dd')}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }
                            
                            {(product.SistaTillganglighetstidpunkt || product.Slutdatum) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">Livscykelslut</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            {product.SistaTillganglighetstidpunkt &&
                                                <tr>
                                                    <th>Sista tillgänglighetstidpunkt</th>
                                                    <td>{format(new Date(product.SistaTillganglighetstidpunkt),'yyyy-MM-dd')}</td>
                                                </tr>
                                            }
                                            {product.Slutdatum &&
                                                <tr>
                                                    <th>Sluttidpunkt för utgående artikel</th>
                                                    <td>{format(new Date(product.Slutdatum),'yyyy-MM-dd')} </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }
        
                            {(product.Sasongsindikator || (product.Sasongskoder && product.Sasongskoder.length > 0)) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">Säsong</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            <Property prop={product.Sasongsindikator} text="Är artikeln en säsongsvara:" />
                
                                            {product.Sasongskoder && product.Sasongskoder.map((kod, i) => {
                                                return (
                                                    <tr key={`sasongskod${i}`}>
                                                        <th>Säsong kod:</th>
                                                        <td>{kod.Sasongskod}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            }
                            
        
                            {product.GPCKod &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">GPC PRODUKTKLASSIFICERING</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            <Property prop={product.GPCKod} text="Produktklass, kod:" />
                                        </tbody>
                                    </table>
                                </div>
                            }
        
                            {product.Ingrediensforteckning &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">INGREDIENSFÖRTECKNING</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            <Property prop={product.Ingrediensforteckning} text="Ingrediensförteckning:" />
                                        </tbody>
                                    </table>
                                </div>
                            }
                            
                            {(product.Alkoholhalt_Formatted !== '' ||
                                (product.Ölbeska && product.Ölbeska.length > 0) || 
                                product.T4203_Argang ||
                                product.KompletterandeProduktklass || 
                                product.T4271_SockerhaltAlkohol ||
                                (product.AlkoholLagringstid && product.AlkoholLagringstid.length > 0) ||
                                (product.TypAvFiltreringsMetodFörAlkoholhaltigDryck && product.TypAvFiltreringsMetodFörAlkoholhaltigDryck.lenght > 0) ||
                                product.TypAvProduktionsmetodFörAlkoholhaltigDryck ||
                                product.PrimärTillsattArom ||
                                product.SekundärTillsattArom ||
                                product.SötmanivåPåAlkoholhaltigDryck ||
                                product.VinetsUrsprung ||
                                (product.AlkoholhaltigDryckDestilleradFrån && product.AlkoholhaltigDryckDestilleradFrån.length > 0)) &&
                            <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">ALKOHOL</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            <Property prop={product.Alkoholhalt_Formatted !== '' && `${product.Alkoholhalt_Formatted}%`} text="Alkoholhalt:" />
                                            <Property prop={product.T4203_Argang} text="Årgång:" />
                                            <PropertyRepetable prop={product.Ölbeska} nameProp="T4333_Namn" valueProp="T4315_Värde_Formatted" text="Ölbeska:" />
                                            <Property prop={product.KompletterandeProduktklass} text="Kompletterande produktklass:" />
                                            <Property prop={product.T4271_SockerhaltAlkohol} text="Sockerhalt för alkoholhaltig dryck:" />
                                            <PropertyRepetable prop={product.AlkoholLagringstid} nameProp="T4270_Namn" valueProp="T4316_Värde_Formatted" text="Lagringstid för alkoholhaltig dryck:" />
                                            <PropertyRepetable prop={product.AlkoholhaltigDryckDestilleradFrån} nameProp="T4317_Namn" text=" Alkoholhaltig dryck destillerad från:" />
                                            <PropertyRepetable prop={product.TypAvFiltreringsMetodFörAlkoholhaltigDryck} nameProp="T4318_Namn" text="Typ av filtreringsmetod för alkoholhaltig dryck:" />
                                            <Property prop={product.TypAvProduktionsmetodFörAlkoholhaltigDryck && product.TypAvProduktionsmetodFörAlkoholhaltigDryck.T4319_Namn} text="Typ av produktionsmetod för alkoholhaltig dryck:" />
                                            <Property prop={product.PrimärTillsattArom && product.PrimärTillsattArom.T4320_Namn} text="Primär tillsatt arom:" />
                                            <Property prop={product.SekundärTillsattArom && product.SekundärTillsattArom.T4321_Namn} text="Sekundär tillsatt arom:" />
                                            <Property prop={product.SötmanivåPåAlkoholhaltigDryck && product.SötmanivåPåAlkoholhaltigDryck.T4322_Namn} text="Sötmanivå på alkoholhaltig dryck:" />
                                            <Property prop={product.VinetsUrsprung && product.VinetsUrsprung.T4323_Namn} text="Vinets ursprung:" />
                                        </tbody>
                                    </table>
                            </div>
                            }
                            
        
                            {(product.Tillverkningslander && product.Tillverkningslander.length > 0) &&
                            <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">TILLVERKNINGSLAND</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            {product.Tillverkningslander.map((country, i) => {
                                                return (
                                                    <tr key={`countrybas${i}`}>
                                                        <th>Tillverkningsland:</th>
                                                        <td>{country.Land}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            }
        
                            {(product.Ursprungsland || product.Ursprungsdeklaration) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">Ursprung</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            <Property prop={product.Ursprungsland} text="Ursprungsområde:" />
                                            <Property prop={product.Ursprungsdeklaration} text="Ursprungsdeklaration:" />
                                        </tbody>
                                    </table>
                                </div>
                            }


                            {(product.Diettyper && product.Diettyper.length > 0) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">Diet</h3>
                                    <table className="product-sheet__table product-sheet__table--column product-sheet__table--striped product-sheet__table--33">
                                        <thead>
                                            <tr>
                                                <th>Diettyp</th>
                                                <th>Diettyp underkategori</th>
                                                <th>Är diettyp märkt på förpackning</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {product.Diettyper.map((diet, i) => {
                                                return (
                                                    <tr key={`diet${i}`}>
                                                        <td>{diet.Typ}</td>
                                                        <td>{diet.SubTyp}</td>
                                                        <td>{diet.ÄrDiettypMärktPåFörpackning}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            }



                            {(product.KlassificeringAvOrganismer && product.KlassificeringAvOrganismer.length > 0) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">KLASSIFICERING AV ORGANISMER</h3>
                                    <table className="product-sheet__table product-sheet__table--column product-sheet__table--striped">
                                        <thead>
                                            <tr>
                                                <th>Släkte</th>
                                                <th>Art</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {product.KlassificeringAvOrganismer.map((organism, i) => {
                                                return (
                                                    <tr key={`organism${i}`}>
                                                        <td>{organism.T4325_Värde}</td>
                                                        <td>{organism.T4326_Värde}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            }
        
                            {(product.FishReportingInformation && product.FishReportingInformation.length > 0) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">FISKERI OCH VATTENBRUK</h3>
                                    {product.FishReportingInformation.map((fishInfo, index) => {
                                        return (
                                            <table key={`FishReportingInformation${index}`} className="product-sheet__table product-sheet__table--striped">
                                                <tbody>
                                                    <Property prop={fishInfo.SpeciesForFisheryStatisticsPurposesName} text="Artens vetenskapliga namn:" />
                                                    <Property prop={fishInfo.SpeciesForFisheryStatisticsPurposesCode} text="Artens vetenskapliga namn, kod:" />
                                                    
                                                    {fishInfo.FishCatchInformation && fishInfo.FishCatchInformation.map((fishCatchInfo, catchIndex) => {
                                                        return (
                                                            <React.Fragment key={`fishCatchInfo${catchIndex}`}>
                                                                <Property prop={fishCatchInfo.ProductionMethodForFishAndSeafoodCode && fishCatchInfo.ProductionMethodForFishAndSeafoodCode.text} text="Produktionsmetod:" />
                                                                <Property prop={fishCatchInfo.StorageStateCode && fishCatchInfo.StorageStateCode.text} text="Förvaringsstatus:" />
                                                                
                                                                {fishCatchInfo.CatchMethodCode && fishCatchInfo.CatchMethodCode.length > 0 &&
                                                                    <tr>
                                                                        <th>Fångstredskap:</th>
                                                                        <td>
                                                                            <ul>
                                                                                {fishCatchInfo.CatchMethodCode.map((redskap, i) => <li key={`redskap${i}`}>{redskap.text}</li>)}
                                                                            </ul>
                                                                        </td>
                                                                    </tr>
                                                                }

                                                                {fishCatchInfo.CatchAreaCode && fishCatchInfo.CatchAreaCode.length > 0 &&
                                                                    <tr>
                                                                        <th>Fångstområde:</th>
                                                                        <td>
                                                                            <ul>
                                                                                {fishCatchInfo.CatchAreaCode.map((zon, i) => <li key={`zon${i}`}>{zon.text}</li>)}
                                                                            </ul>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                            </React.Fragment>                                                                
                                                        );
                                                    })}                                                    
                                                </tbody>
                                            </table>
                                        );
                                    })}
                                </div>
                            }

                            {product.T4246_BeredningsformText &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">JORDBRUK OCH BEARBETNING</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            <Property prop={product.T4246_BeredningsformText} text="Beredningsform, kod:" />
                                        </tbody>
                                    </table>
                                </div>
                            }            

                            {(product.Receptlinks && product.Receptlinks.length > 0) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">Recept</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            <tr>
                                                <th>Receptlänk{product.Receptlinks.length > 1 && 'ar'}:</th>
                                                <td>
                                                    <ul>
                                                    {product.Receptlinks.map((recept, i) => {
                                                        return (
                                                            <li key={`recipelink${i}`}>
                                                                <a href={recept.ReceptURL}>{recept.Receptnamn}</a>
                                                            </li>
                                                        );
                                                    })}
                                                    </ul>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            }
        
                            {(product.Importklassificeringstyp || product.TullstatistisktNummer) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">TULLSTATISTISKT NUMMER:</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            <Property prop={product.Importklassificeringstyp} text="Importklassificeringstyp:" />
                                            <Property prop={product.TullstatistisktNummer} text="Importklassificering:" />
                                        </tbody>
                                    </table>
                                </div>
                            }
        
                            {product.IngrediensforteckningIckeLivsmedel &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3>INGREDIENSFÖRTECKNING FÖR ICKE LIVSMEDEL</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            <Property prop={product.IngrediensforteckningIckeLivsmedel} text="Ingrediensförteckning för icke-livsmedel:" />
                                        </tbody>
                                    </table>
                                </div>
                            }
                            
                            {(product.Substanser && product.Substanser.length > 0) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">Innehållsämnen (icke livsmedel)</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            {product.Substanser.map((substans, i) => {
                                                return (
                                                    <React.Fragment key={`substans${i}`}>
                                                        {substans.InnehallsamnetNamn &&
                                                            <tr>
                                                                <th>Innehållsämne:</th>
                                                                <td>{substans.InnehallsamnetNamn}</td>
                                                            </tr>
                                                        }
                                                        {substans.ArInnehallsamneAktiv !== null &&
                                                            <tr>
                                                                <th>Är innehållsämnet aktivt?:</th>
                                                                <td>{substans.ArInnehallsamneAktiv ? 'Ja': 'Nej'}</td>
                                                            </tr>
                                                        }
                                                    </React.Fragment>
                                                );
                                            })}    
                                            
                                        </tbody>
                                    </table>
                                </div>
                            }
               
                            {product.PlaceOfProductActivities && product.PlaceOfProductActivities.length > 0 &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">DETALJINFORMATION OM ARTIKELNS AKTIVITET OCH URSPRUNG</h3>
                                    <table className="product-sheet__table product-sheet__table--striped product-sheet__table--column product-sheet__table--50">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Land för aktivitet
                                                </th>
                                                <th>
                                                    Typ av artikelaktivitet
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {product.PlaceOfProductActivities.map((placeOfProductActivities, i) => {
                                                return (
                                                    <tr key={`placeOfProductActivities${i}`}>
                                                       <td>{placeOfProductActivities.CountryOfActivity.Text}</td>
                                                       <td>{placeOfProductActivities.productActivityTypeCode.Text}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            }
                            
                            {product.TradeItemOrganicInformation && product.TradeItemOrganicInformation.OrganicProductPlaceOfFarmingCode &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">EKOLOGISK INFORMATION FÖR ARTIKELN</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            <Property prop={product.TradeItemOrganicInformation.OrganicProductPlaceOfFarmingCode.Text} text="Produktionsplats för ekologiskt jordbruk:" />
                                        </tbody>
                                    </table>
                                </div>
                            }

                            {product.TradeItemOrganicInformation && product.TradeItemOrganicInformation.OrganicClaim && product.TradeItemOrganicInformation.OrganicClaim.OrganicTradeItemCode &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">EKOLOGISKT PÅSTÅENDE</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            <Property prop={product.TradeItemOrganicInformation.OrganicClaim.OrganicTradeItemCode.Text} text="Ekologisk status:" />
                                        </tbody>
                                    </table>
                                </div>
                            }

                            {product.TradeItemOrganicInformation && product.TradeItemOrganicInformation.OrganicClaim && product.TradeItemOrganicInformation.OrganicClaim.OrganicCertifications && product.TradeItemOrganicInformation.OrganicClaim.OrganicCertifications.length > 0 &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">EKOLOGISKT CERTIFIKAT</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            {product.TradeItemOrganicInformation.OrganicClaim.OrganicCertifications.map((cert, i) => {
                                                return(
                                                    <React.Fragment key={`organic-cert${i}`}>
                                                        <Property prop={cert.OrganicCertificationIdentification} text="Ekologiskt certifikat identifiering:" />
                                                    </React.Fragment>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            }


                            {(product.Forvaringsinstruktion || product.Anvandningsinstruktioner || product.T4306_Atervinningsinstruktioner) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">KONSUMENTINSTRUKTIONER</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            {product.Forvaringsinstruktion &&
                                                <tr>
                                                    <th>Förvaringsinstruktioner för konsument:</th>
                                                    <td>{product.Forvaringsinstruktion}</td>
                                                </tr>
                                            }
                                            {product.Anvandningsinstruktioner &&
                                                <tr>
                                                    <th>Användningsinstruktioner</th>
                                                    <td>{product.Anvandningsinstruktioner}</td>
                                                </tr>
                                            }
                                            <Property prop={product.T4306_Atervinningsinstruktioner} text="Återvinningsinstruktioner:" />
                                        </tbody>
                                    </table>
                                </div>
                            }

                            {((product.Temperaturinformation && product.Temperaturinformation.length > 0) ||
                            product.T4242_Temperaturstatus ||
                            (product.Hanteringsinstruktioner && product.Hanteringsinstruktioner.length > 0)) &&
                            <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">TEMPERATUR</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            {product.Temperaturinformation && product.Temperaturinformation.filter(f => f.T3822_Kod === 'STORAGE_HANDLING').length > 0 &&
                                                <>
                                                    {product.Temperaturinformation.filter(f => f.T3822_Kod === 'STORAGE_HANDLING').map((temp, i) => {
                                                        return (
                                                            <React.Fragment key={`storage-temp${i}`}>
                                                                <PropertyNumber prop={temp.T3796_MaxTemperatur_Varde !== null && `${temp.T3796_MaxTemperatur_Varde} °C`} text="Högsta förvaringstemperatur:" />
                                                                <PropertyNumber prop={temp.T3797_MinTemperatur_Varde !== null && `${temp.T3797_MinTemperatur_Varde} °C`} text="Lägsta förvaringstemperatur:" />
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </>
                                            }
                                            {product.Temperaturinformation && product.Temperaturinformation.filter(f => f.T3822_Kod === 'TRANSPORTATION').length > 0 &&
                                                <>
                                                    {product.Temperaturinformation.filter(f => f.T3822_Kod === 'TRANSPORTATION').map((temp, i) => {
                                                        return (
                                                            <React.Fragment key={`transport-temp${i}`}>
                                                                <PropertyNumber prop={temp.T3796_MaxTemperatur_Varde !== null && `${temp.T3796_MaxTemperatur_Varde} °C`} text="Högsta transporttemperatur:" />
                                                                <PropertyNumber prop={temp.T3797_MinTemperatur_Varde !== null && `${temp.T3797_MinTemperatur_Varde} °C`} text="Lägsta transporttemperatur:" />
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </>
                                            }
                                            <Property prop={product.T4242_Temperaturstatus} text="Temperaturstatus:" />
                                            <PropertyRepetable prop={product.Hanteringsinstruktioner} nameProp="Hanteringsinstruktion" text="Hanteringsinstruktion:" />
                                        </tbody>
                                    </table>
                            </div>
                            }
                            
                            {((product.RelativLuftfuktighetMax !== null && product.RelativLuftfuktighetMax !== undefined && product.RelativLuftfuktighetMax !== 0) || 
                            (product.RelativLuftfuktighetMin !== null && product.RelativLuftfuktighetMin !== undefined && product.RelativLuftfuktighetMin !== 0)) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">FUKTIGHET</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            <Property prop={product.RelativLuftfuktighetMax && `${product.RelativLuftfuktighetMax}%`} text="Maximum luftfuktighet:" />
                                            <Property prop={product.RelativLuftfuktighetMin && `${product.RelativLuftfuktighetMin}%`} text="Minimum luftfuktighet:" />
                                        </tbody>
                                    </table>
                                </div>
                            }
        
                            {((product.TotalHallbarhetAntalDagar !== undefined && product.TotalHallbarhetAntalDagar > 0) ||
                            (product.HallbarhetEfterOppning !== undefined && product.HallbarhetEfterOppning > 0) ||
                            (product.OpenJar !== undefined && product.OpenJar > 0)  ||
                            (product.OpenJarTidText !== undefined)) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">Livslängd</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            <Property prop={product.TotalHallbarhetAntalDagar} text="Total hållbarhet (dagar):" />
                                            <Property prop={product.HallbarhetEfterOppning} text="Hållbarhet efter öppning:" />
                                            <Property prop={product.OpenJar_Formatted} text="Open Jar:" />
                                            <Property prop={product.OpenJarTidText} text="Måttenhet för tid kod:" />
                                        </tbody>
                                    </table>
                                </div>
                            } 

                            {(modules['Marks/ProductInformationDetail/ClaimDetail'] && modules['Marks/ProductInformationDetail/ClaimDetail'].ValueSets.length > 0) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">PÅSTÅENDE</h3>
                                    <table className="product-sheet__table product-sheet__table--striped product-sheet__table--column product-sheet__table--33">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Ämne
                                                </th>
                                                <th>
                                                    Typ av påstående
                                                </th>
                                                <th>
                                                    Är påstående märkt på förpackningen
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {modules['Marks/ProductInformationDetail/ClaimDetail'].ValueSets.map((claimDetailValueSet, i) => {
                                                return (
                                                    <tr key={`claimDetailValueSet${i}`}>
                                                        {claimDetailValueSet.map((claimDetail, x) => {
                                                            return (
                                                                <td key={`claimDetail${x}`}>{claimDetail.Value}</td>
                                                            );
                                                        })}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            }
                             
                            {(modules['Marks/PoductInformationDetail/ClaimDescription'] && modules['Marks/PoductInformationDetail/ClaimDescription'].ValueSets.length > 0) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">Övriga påståenden</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            {modules['Marks/PoductInformationDetail/ClaimDescription'].ValueSets.map((claimDescription, i) => {
                                                if (!claimDescription[0]) return null;

                                                if (claimDescription[0].LanguageCode !== 'sv') return null;

                                                return (
                                                    <React.Fragment key={`claimDescription${i}`}>
                                                        <Property prop={`${claimDescription[0].Value}`} text="Övriga påståenden:" />
                                                    </React.Fragment>
                                                );
                                            })}
                                        </tbody>
                                    </table>

                                </div>
                            }
        
                            {((modules['HealthCareItemInformation/HealthcareItemInformation/PrescriptionTypeCode'] && modules['HealthCareItemInformation/HealthcareItemInformation/PrescriptionTypeCode'].ValueSets.length > 0) || product.OvrigRegulatoriskMarkning || product.Halsopastaende || product.T4273_InnehallerLatex) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">HÄLSA</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            <Property prop={product.OvrigRegulatoriskMarkning} text="Övrig regulatorisk märkningsinformation:" />
                                            <Property prop={product.T4273_InnehallerLatex && product.T4273_InnehallerLatex === true ? 'Ja' : 'Nej'} text="Innehåller latex:" />

                                            {modules['HealthCareItemInformation/HealthcareItemInformation/PrescriptionTypeCode'] && modules['HealthCareItemInformation/HealthcareItemInformation/PrescriptionTypeCode'].ValueSets.map((prescriptionTypeCodeValueSet, i) => {
                                                return prescriptionTypeCodeValueSet.map(prescriptionTypeCode => {
                                                    return (
                                                        <React.Fragment key={`prescriptionTypeCode${i}`}>
                                                            <Property prop={prescriptionTypeCode.Value} text="Typ av recept:" />
                                                        </React.Fragment>
                                                    );
                                                });
                                            })}
                                        
                                        </tbody>
                                    </table>
                                </div>
                            }
        
                            {(product.Artikelegenskap || product.T4274_MriKompatibilitet || product.T4275_ManufacturerSterilisation) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">Vårdprodukter</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            <Property prop={product.Artikelegenskap} text="Artikelegenskap, kod:" />
                                            <Property prop={product.T4274_MriKompatibilitet} text="MRI-kompatibilitet:" />
                                            <Property prop={product.T4275_ManufacturerSterilisation} text="Typ av tillverkarens sterilisering:" />
                                        </tbody>
                                    </table>
                                </div>
                            }
        
                            {(product.Datumstandard || product.T4032_TypAvDatumPaForpackningen_Text) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">DATUMMÄRKNING</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            <Property prop={product.Datumstandard} text="Format för datummärkning, kod:" />
                                            <Property prop={product.T4032_TypAvDatumPaForpackningen_Text} text="Typ av datum på förpackningen:" />
                                        </tbody>
                                    </table>
                                </div>
                            }
        
                            {(product.MaskinellMarkningar && product.MaskinellMarkningar.length > 0) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">Datamärkning</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            {product.MaskinellMarkningar.map((mmarkning, i) => {
                                                return (
                                                    <React.Fragment key={`MaskinellMarkningar${i}`}>
                                                        <Property prop={mmarkning.MaskinellMarkning} text="Förekomst av streckkodad information:" />
                                                        <Property prop={mmarkning.TypAvMarkning} text="Typ av maskinellt läsbar märkning:" />
                                                        <Property prop={mmarkning.Databarartyp} text="Databärartyp:" />
                                                    </React.Fragment>
                                                    
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            }
                            
                            {((product.Skattesats && product.Skattesats !== 0) || product.SkattetypText) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">SKATT</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            <Property prop={product.Skattesats ? `${product.Skattesats_Formated}%` : null} text="Skattesats" />
                                            <Property prop={product.SkattetypText} text="Skattetyp:" />
                                        </tbody>
                                    </table>
                                </div>
                            }
        
                            {(product.Batchnummer || product.Returemballage) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">MÄRKNING FÖRPACKNING</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            <Property prop={product.Batchnummer ? 'Ja' : product.Batchnummer === false ? 'Nej' : null} text="Har artikeln batchnummer:" />
                                            <Property prop={product.Returemballage ? 'Ja' : product.Returemballage === false ? 'Nej' : null} text="Är förpackningen returnerbar:" />
                                        </tbody>
                                    </table>
                                </div>
                            }
                            
                            {(product.UppfyllerRoHS !== null && product.UppfyllerRoHS  !== undefined) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">HÅLLBAR UTVECKLING</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            <Property prop={product.UppfyllerRoHS} text="Är artikeln RoHS-kompatibel:" />
                                        </tbody>
                                    </table>
                                </div>
                            }
        
                            {product.Garantiloptid !== 0 &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">GARANTI</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            <Property prop={product.Garantiloptid} text="Garantilöptid, värde:" />
                                        </tbody>
                                    </table>
                                </div>
                            }
        
                            {(product.Forpackningar && product.Forpackningar.length > 0) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">Förpackning/Plattformstyp:</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            {product.Forpackningar.map((forpackning, i) => {
                                                return (
                                                    <React.Fragment key={`forpackning${i}`}>
                                                        <Property prop={forpackning.Forpackningstyp} text="Förpacknings-/kollityp:" />
                                                        <Property prop={forpackning.ForpackningsmaterialVillkorKod === true ? 'Återvinningsavgift betald' : null} text="Villkor för förpackningsmaterial, kod:" />
                                                        <Property prop={forpackning.Plattformstyp} text="Plattformstyp kod:" />
                                                        <Property prop={forpackning.Plattformsvikt && `${forpackning.Plattformsvikt_Formatted} ${forpackning.EmballageViktEnhet}`} text="Förpackningsvikt:" />
                                                        <Property prop={forpackning.T4201_plattformshojd_mm && `${forpackning.T4201_plattformshojd_mm}mm`} text="Plattformshöjd:" />
                                                        <Property prop={forpackning.Antalenheter} text="Antal enheter:" />
                                                        <Property prop={forpackning.PantForReturnerbarForpackningKod} text="Pantkod:" />
                                                        <Property prop={forpackning.Forpackningsegenskap} text="Förpackningsegenskap:" />
                                                        <Property prop={forpackning.Forpakningsfunktion} text="Förpackningsfunktion:" />
                                                        <Property prop={forpackning.T4372_PackagingSustainabilityFeatureText} text="Förpackningens hållbarhetsegenskap, kod:" />
                                                        <Property prop={forpackning.AntalenhetKod} text="Antal enheter:" />
                                                        {forpackning.Forpackningsmaterial && forpackning.Forpackningsmaterial.length > 0 &&
                                                            <tr>
                                                                <th>Förpackningsmaterial:</th>
                                                                <td>
                                                                    <ul>
                                                                        {forpackning.Forpackningsmaterial && forpackning.Forpackningsmaterial.map((material, x) => {
                                                                            return (
                                                                                <React.Fragment key={`material${x}`}>
                                                                                    <li> 
                                                                                        <span>{material.T1188_ForpackningsmaterialKod}</span><br />
                                                                                        {material.T1189_Vikt !== null && material.T1189_Vikt > 0 &&
                                                                                            <>
                                                                                                <span>- Vikt: {material.T1189_Vikt_Formatted} {material.T3780_ViktEnhet}</span><br />
                                                                                            </>
                                                                                        }
                                                                                        {material.T4243_ForpackningsmaterialetsFargKod &&
                                                                                            <>
                                                                                                <span>- Färg: {material.T4243_ForpackningsmaterialetsFargKod}</span><br />
                                                                                            </>
                                                                                        }
                                                                                        {material.T4244_ForpackningsmaterialetsBelaggning &&
                                                                                            <>
                                                                                                <span>- Beläggning: {material.T4244_ForpackningsmaterialetsBelaggning}</span><br />
                                                                                            </>
                                                                                        }
                                                                                        {material.T4283_PackagingLabellingCoveragePercentage &&
                                                                                            <>
                                                                                                <span>- Täkt yta: {material.T4283_PackagingLabellingCoveragePercentage_Formatted}</span><br />
                                                                                            </>
                                                                                        }
                                                                                        {material.T4284_PackagingLabellingTypeCode_AATEXT &&
                                                                                            <>
                                                                                                <span>- Typ av etikett/tryck: {material.T4284_PackagingLabellingTypeCode_AATEXT}</span><br />
                                                                                            </>
                                                                                        }
                                                                                        {material.T4383_NumberOfPackagingElementUnits &&
                                                                                            <>
                                                                                                <span>- Antal ingående förpackningsdelar per material: {material.T4383_NumberOfPackagingElementUnits}</span><br />
                                                                                            </>
                                                                                        }
                                                                                        {material.T4384_PackagingMaterialAdhesiveAmount &&
                                                                                            <>
                                                                                                <span>- Förpackningsmaterialets limmängd: {material.T4384_PackagingMaterialAdhesiveAmount.Value_Formatted} {material.T4384_PackagingMaterialAdhesiveAmount.Unit}</span><br />
                                                                                            </>
                                                                                        }
                                                                                        {material.T4385_PackagingMaterialAdhesiveSolubilityTypeCodes && material.T4385_PackagingMaterialAdhesiveSolubilityTypeCodes.map((adhesive, index) => {
                                                                                            return (
                                                                                                <React.Fragment key={`${adhesive.Code}${index}`}>
                                                                                                    <span>- Löslighet för förpackningsmaterialets lim: {adhesive.Name}</span><br />
                                                                                                </React.Fragment>
                                                                                            );
                                                                                        })}
                                                                                        {material.T4386_PackagingMaterialAdhesiveTypeCodes && material.T4386_PackagingMaterialAdhesiveTypeCodes.map((adhesiveTypeCodes, index) => {
                                                                                            return (
                                                                                                <React.Fragment key={`${adhesiveTypeCodes.Code}${index}`}>
                                                                                                    <span>- Förpackningsmaterialets lim: {adhesiveTypeCodes.Name}</span><br />
                                                                                                </React.Fragment>
                                                                                            );
                                                                                        })}
                                                                                        {material.T4285_PackagingRawMaterialCode__AATEXT &&
                                                                                            <>
                                                                                                <span>- Förpackningsråvara: {material.T4285_PackagingRawMaterialCode__AATEXT}</span><br />
                                                                                            </>
                                                                                        }
                                                                                        {material.T4286_PackagingRawMaterialContentPercentage &&
                                                                                            <>
                                                                                                <span>- Förpackningsråvara andel: {material.T4286_PackagingRawMaterialContentPercentage_Formatted}%</span><br />
                                                                                            </>
                                                                                        }
                                                                                        {material.T4371_IsPackagingMaterialRecoverableText &&
                                                                                            <>
                                                                                                <span>- Är förpacknings-materialet återvinningsbart: {material.T4371_IsPackagingMaterialRecoverableText}</span><br />
                                                                                            </>
                                                                                        }
                                                                                        {material.T4354_PackagingMaterialElementCodeText &&
                                                                                            <>
                                                                                                <span>- Förpackningsdel: {material.T4354_PackagingMaterialElementCodeText}</span><br />
                                                                                            </>
                                                                                        }
                                                                                        {material.T4355_PackagingMaterialRecyclingSchemeCodeText &&
                                                                                            <>
                                                                                                <span>- Förpackningsmaterialets återvinningsnummer: {material.T4355_PackagingMaterialRecyclingSchemeCode} - {material.T4355_PackagingMaterialRecyclingSchemeCodeText}</span><br />
                                                                                            </>
                                                                                        }
                                                                                    </li> 
                                                                                </React.Fragment>
                                                                            );
                                                                                
                                                                            
                                                                        })}
                                                                    </ul>
                                                                    
                                                                </td>
                                                            </tr>
                                                        }

                                                        {forpackning.Förpackningspåstående && forpackning.Förpackningspåstående.length > 0 &&
                                                            <tr>
                                                                <th>Förpackningspåstående:</th>
                                                                <td>
                                                                    {forpackning.Förpackningspåstående && forpackning.Förpackningspåstående.map((material, x) => {
                                                                        return (
                                                                            <ul key={`materialpastaende${x}`}>
                                                                                <li>
                                                                                    {material.T4387_PackagingClaimElementCode &&
                                                                                        <>
                                                                                            <span>{material.T4387_PackagingClaimElementCode.Name}</span><br />
                                                                                        </>
                                                                                    }
                                                                                    {material.T4387_PackagingClaimElementCode &&
                                                                                        <>
                                                                                            <span>- Förpackningspåstående på förpackningen: {material.T4387_PackagingClaimElementCode.Name}</span><br />
                                                                                        </>
                                                                                    }
                                                                                    {material.T4389_IsPackagingClaimRegulated &&
                                                                                        <>
                                                                                            <span>- Är förpackningspåstående reglerat: {material.T4389_IsPackagingClaimRegulated.Name}</span><br />
                                                                                        </>
                                                                                    }
                                                                                    {material.T4390_PackagingClaimTypeCode &&
                                                                                        <>
                                                                                            <span>- Typ av förpackningspåstående: {material.T4390_PackagingClaimTypeCode.Name}</span><br />
                                                                                        </>
                                                                                    }
                                                                                </li> 
                                                                            </ul>
                                                                        ); 
                                                                    })}
                                                                </td>
                                                            </tr>
                                                        }
                                                        
                                                        
                                                    </React.Fragment>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            }

                            <div className="product-sheet-box__tablecontent">
                                <h3 className="product-sheet-box__tableheading">VIKT</h3>
                                <table className="product-sheet__table product-sheet__table--striped">
                                    <tbody>
                                        <Property prop={product.Bruttovikt && `${product.Bruttovikt} gram`} text="Artikelns bruttovikt:" />
                                        <Property prop={product.MangdFardigVara && `${product.MangdFardigVara_Formatted} ${product.MangdFardigVaraEnhet}`} text="Jämförmängd färdigvara:" />
                                        <Property prop={product.MangdFardigVaraAvser} text="Jämförmängd kod:" />
                                        <PropertyBoolean prop={product.Variabelmattsindikator} text="Variabelmåttsvara:" />
                                        <Property prop={product.T4330_Nettovikt && `${product.T4330_Nettovikt.T4330_Värde_Formatted} gram`} text="Artikelns nettovikt:" />
                                    </tbody>
                                </table>
                            </div>

                            <Nettoinnehall {...product} Property={(props) => Property(props)} />

                            {(product.Sillvikt !== null && product.Sillvikt > 0) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">SPECIFICERING AV INNEHÅLL</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            <Property prop={product.Sillvikt && `${product.Sillvikt} gram`} text="Sillvikt:" />
                                        </tbody>
                                    </table>
                                </div>
                            }

        
                            {(product.MinstaAntalEnheterIforpackningen || product.MaximaltAntalEnheterIforpackningen || product.MinstaEnhetSort) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">MINSTA ENHET (OM 2 ELLER FLER)</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            <Property prop={product.MinstaAntalEnheterIforpackningen} text="Minsta antal enheter i förpackningen:" />
                                            <Property prop={product.MaximaltAntalEnheterIforpackningen} text="Maximalt antal enheter i förpackningen:" />
                                            <Property prop={product.MinstaEnhetSort} text="Sort:" />
                                        </tbody>
                                    </table>
                                </div>
                            }
                            
                            {(product.Bredd || product.Djup || product.Hojd) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">Mått</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            <Property prop={product.Bredd && `${product.Bredd} mm`} text="Artikelns bredd:" />
                                            <Property prop={product.Djup && `${product.Djup} mm`} text="Artikelns djup:" />
                                            <Property prop={product.Hojd && `${product.Hojd} mm`} text="Artikelns höjd:" />
                                        </tbody>
                                    </table>   
                                </div>
                            }

                            {(modules['Dimensions/TradeItemSizeModule/nonPackagedSizeDimension/sizeDimension'] && modules['Dimensions/TradeItemSizeModule/nonPackagedSizeDimension/sizeDimension'].ValueSets.length > 0) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">Storleksmått</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            {modules['Dimensions/TradeItemSizeModule/nonPackagedSizeDimension/sizeDimension'].ValueSets.map((sizeDimension, i) => {
                                                if (!sizeDimension[0]) return null;

                                                return (
                                                    <React.Fragment key={`sizeDimension${i}`}>
                                                        <Property prop={`${sizeDimension[0].Value_Formatted} ${sizeDimension[1] && sizeDimension[1].Value} (${sizeDimension[2] && sizeDimension[2].Value})`} text="Storleksmått:" />
                                                    </React.Fragment>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            }



                            {(product.T3816_DimensionTypeCode_Text ||
                            (product.Hyllbredd !== undefined && product.Hyllbredd > 0) ||
                            (product.Hylldjup !== undefined && product.Hylldjup >  0) ||
                            (product.Hyllhojd !== undefined && product.Hyllhojd > 0)) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">ÖVRIGA MÅTT</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            <Property prop={product.T3816_DimensionTypeCode_Text} text="Typ av övrigt mått, kod" />
                                            <Property prop={product.Hyllbredd} text="Övrig bredd:" />
                                            <Property prop={product.Hylldjup} text="Övrigt djup:" />
                                            <Property prop={product.Hyllhojd} text="Övrig höjd:" />
                                        </tbody>
                                    </table>   
                                </div>
                            }
        
                            {(product.Staplingshojd || product.Staplingsriktning) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">Stapling</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            <Property prop={product.Staplingshojd && `${product.Staplingshojd} mm`} text="Staplingshöjd, värde:" />
                                            <Property prop={product.Staplingsriktning} text="Staplingsriktning" />
                                        </tbody>
                                    </table>   
                                </div>
                            }
        
                            {(product.T0157_Staplingsbarhet || product.Staplingsbarhet || product.T3848_TypAvStaplingsbarhet) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">Staplingsbarhet</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            <Property prop={(product.T0157_Staplingsbarhet || product.Staplingsbarhet) && `${product.T0157_Staplingsbarhet || product.Staplingsbarhet} ${product.Niva && product.Niva.toLowerCase()}förpackningar i höjd`} text="Staplingsbarhet" />
                                            <Property prop={staplingsbarhet(product.T3848_TypAvStaplingsbarhet)} text="Typ av staplingsbarhet:" />
                                        </tbody>
                                    </table>   
                                </div>
                            }

                            {(product.AntalForpackningarPerLav ||
                            product.AntalIngaendeLav ||
                            product.OregelbundetPackmonster) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">PACKMÖNSTER</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            <Property prop={`${product.AntalForpackningarPerLav} st`} text="Antal per lav:" />
                                            <Property prop={`${product.AntalIngaendeLav} st`} text="Antal ingående lav:" />
                                            <Property prop={product.OregelbundetPackmonster && product.OregelbundetPackmonster === true && 'Ja'} text="Är artikelns packmönster oregelbundet:" />
                                        </tbody>
                                    </table>   
                                </div>
                            }

                            {(product.Faktortyp !== undefined && product.Faktortyp !== 0) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">FAKTOR</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            <Property prop={product.Faktor} text={product.FaktortypText} />
                                        </tbody>
                                    </table>   
                                </div>
                            }


                            {(modules['BeautyPersonalCareHygieneDetail/productInformationDetail/formationTypeCode'] || 
                              modules['BeautyPersonalCareHygieneDetail/productInformationDetail/timeOfApplicationCode']) && 
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">SKÖNHET OCH HYGIENINFORMATION</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            {modules['BeautyPersonalCareHygieneDetail/productInformationDetail/formationTypeCode'] && modules['BeautyPersonalCareHygieneDetail/productInformationDetail/formationTypeCode'].ValueSets.map((formationTypeValueSet, i) => {
                                                return formationTypeValueSet.map(formationType => {
                                                    return (
                                                        <React.Fragment key={`productInfo${i}`}>
                                                            <Property prop={formationType.Value} text="Typ av struktur:" />
                                                        </React.Fragment>
                                                        );
                                                });
                                            })}
                                            {modules['BeautyPersonalCareHygieneDetail/productInformationDetail/timeOfApplicationCode'] && modules['BeautyPersonalCareHygieneDetail/productInformationDetail/timeOfApplicationCode'].ValueSets.map((timeOfApplicationValueSet, i) => {
                                                return timeOfApplicationValueSet.map(timeOfApplication => {
                                                    return (
                                                        <React.Fragment key={`timeOfApplication${i}`}>
                                                            <Property prop={timeOfApplication.Value} text="Appliceringstidpunkt:" />
                                                        </React.Fragment>
                                                    );
                                                });
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            }

                            {(modules['BeautyPersonalCareHygieneDetail/productInformationDetail/beautyPersonalCareHygieneDetail/hairProductInformation/hairProductFunctionOrTreatmentCode'] || 
                              modules['BeautyPersonalCareHygieneDetail/productInformationDetail/beautyPersonalCareHygieneDetail/hairProductInformation/targetHairTypeCode'] ||
                              modules['BeautyPersonalCareHygieneDetail/productInformationDetail/beautyPersonalCareHygieneDetail/hairProductInformation/naturalHairColourCode']) && 
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">HÅRPRODUKTER</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            {modules['BeautyPersonalCareHygieneDetail/productInformationDetail/beautyPersonalCareHygieneDetail/hairProductInformation/hairProductFunctionOrTreatmentCode'] && modules['BeautyPersonalCareHygieneDetail/productInformationDetail/beautyPersonalCareHygieneDetail/hairProductInformation/hairProductFunctionOrTreatmentCode'].ValueSets.map((hairProductFunctionOrTreatmentValueSet, i) => {
                                                return hairProductFunctionOrTreatmentValueSet.map(hairProductFunctionOrTreatment => {
                                                    return (
                                                        <React.Fragment key={`hairProductFunctionOrTreatment${i}`}>
                                                            <Property prop={hairProductFunctionOrTreatment.Value} text="Produktfunktion hårvård:" />
                                                        </React.Fragment>
                                                        );
                                                });
                                            })}
                                            {modules['BeautyPersonalCareHygieneDetail/productInformationDetail/beautyPersonalCareHygieneDetail/hairProductInformation/targetHairTypeCode'] && modules['BeautyPersonalCareHygieneDetail/productInformationDetail/beautyPersonalCareHygieneDetail/hairProductInformation/targetHairTypeCode'].ValueSets.map((targetHairTypeValueSet, i) => {
                                                return targetHairTypeValueSet.map(targetHairType => {
                                                    return (
                                                        <React.Fragment key={`targetHairType${i}`}>
                                                            <Property prop={targetHairType.Value} text="Hårtyp:" />
                                                        </React.Fragment>
                                                    );
                                                });
                                            })}
                                            {modules['BeautyPersonalCareHygieneDetail/productInformationDetail/beautyPersonalCareHygieneDetail/hairProductInformation/naturalHairColourCode'] && modules['BeautyPersonalCareHygieneDetail/productInformationDetail/beautyPersonalCareHygieneDetail/hairProductInformation/naturalHairColourCode'].ValueSets.map((naturalHairColourValueSet, i) => {
                                                return naturalHairColourValueSet.map(naturalHairColour => {
                                                    return (
                                                        <React.Fragment key={`naturalHairColour${i}`}>
                                                            <Property prop={naturalHairColour.Value} text="Naturlig hårfärg:" />
                                                        </React.Fragment>
                                                    );
                                                });
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            }

                            {(modules['BeautyPersonalCareHygieneDetail/productInformationDetail/beautyPersonalCareHygieneDetail/skinProductInformation/skinProductFunctionOrTreatmentCode'] || 
                              modules['BeautyPersonalCareHygieneDetail/productInformationDetail/beautyPersonalCareHygieneDetail/skinProductInformation/targetSkinTypeCode']) && 
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">HUDVÅRDSPRODUKTER</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            {modules['BeautyPersonalCareHygieneDetail/productInformationDetail/beautyPersonalCareHygieneDetail/skinProductInformation/skinProductFunctionOrTreatmentCode'] && modules['BeautyPersonalCareHygieneDetail/productInformationDetail/beautyPersonalCareHygieneDetail/skinProductInformation/skinProductFunctionOrTreatmentCode'].ValueSets.map((skinProductFunctionOrTreatmentValueSet, i) => {
                                                return skinProductFunctionOrTreatmentValueSet.map(skinProductFunctionOrTreatment => {
                                                    return (
                                                        <React.Fragment key={`skinProductFunctionOrTreatment${i}`}>
                                                            <Property prop={skinProductFunctionOrTreatment.Value} text="Produktfunktion hudvård:" />
                                                        </React.Fragment>
                                                        );
                                                });
                                            })}
                                            {modules['BeautyPersonalCareHygieneDetail/productInformationDetail/beautyPersonalCareHygieneDetail/skinProductInformation/targetSkinTypeCode'] && modules['BeautyPersonalCareHygieneDetail/productInformationDetail/beautyPersonalCareHygieneDetail/skinProductInformation/targetSkinTypeCode'].ValueSets.map((targetSkinTypeValueSet, i) => {
                                                return targetSkinTypeValueSet.map(targetSkinType => {
                                                    return (
                                                        <React.Fragment key={`targetSkinType${i}`}>
                                                            <Property prop={targetSkinType.Value} text="Hudtyp:" />
                                                        </React.Fragment>
                                                    );
                                                });
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            }


                            {(product.BatterierIngar || 
                            product.BatterierKravs || 
                            product.FinnsInbyggdaBatterier || 
                            product.T4750_AntalInbyggdaBatterier > 0 || 
                            product.BatterierKravAntal > 0 ||
                            product.Batterivikt > 0 || 
                            product.Batterityp ||
                            product.Batteriteknik) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">BATTERIER</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            <Property prop={product.BatterierIngar && product.BatterierIngar === true ? 'Ja' : 'Nej'} text="Ingår batterier:" />
                                            <Property prop={product.BatterierKravs && product.BatterierKravs === true ? 'Ja' : 'Nej'} text="Krävs batterier:" />
                                            <Property prop={product.FinnsInbyggdaBatterier} text="Finns inbyggda batterier:" />
                                            <Property prop={product.T4750_AntalInbyggdaBatterier} text="Antal inbyggda batterier:" />
                                            <Property prop={product.BatterierKravAntal} text="Antal batterier som krävs:" />
                                            <Property prop={product.Batterityp} text="Batterityp:" />
                                            <Property prop={product.Batterivikt && `${product.Batterivikt_Formatted} gram`} text="Batterivikt:" />
                                            <Property prop={product.Batteriteknik} text="Batteriteknik:" />
                                        </tbody>
                                    </table>
                                </div>
                            }
        
                            {(product.FarligtGodsBegransadMangd || 
                            product.TunnelrestriktionADR || 
                            product.Flampunkt || 
                            product.FarligtGodsOvrigInfo || 
                            product.Klassificeringssystem ||
                            product.T4303_NettoviktFarligtGods) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">FARLIGT GODS</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            <Property prop={product.FarligtGodsBegransadMangd} text="Farligt gods begränsad mängd:" />
                                            <Property prop={product.TunnelrestriktionADR} text="Tunnelrestriktion ADR:" />
                                            <Property prop={product.Flampunkt_Formatted} text="Flampunkt:" />
                                            <Property prop={product.FarligtGodsOvrigInfo} text="Farligt gods, övrig information:" />
                                            <Property prop={product.Klassificeringssystem} text="Klassificeringssystem:" /> 
                                            <Property prop={product.T4303_NettoviktFarligtGods !== null ? `${product.T4303_NettoviktFarligtGods} ${product.T4303_NettoviktFarligtGodsEnhetText && product.T4303_NettoviktFarligtGodsEnhetText.toLowerCase()}` : null} text="Nettovikt farligt gods:" /> 
                                        </tbody>
                                    </table>
                                </div>
                            }
        
                            {(product.FarligtGodsKlass ||
                            product.OfficiellTransportbenamning ||
                            product.FarligtGodsSarbestammelser ||
                            product.FarligtGodsForpackningsgrupp || 
                            product.Transportkategori ||
                            product.FarligtGodsKod || 
                            product.KlassificeringskodFarligtgods ||
                            product.OspecificeradTransportbenamning ||
                            product.NettoviktFarligtGods ||
                            (product.Varningsetiketter && product.Varningsetiketter.length > 0)) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">SPECIFIKATION FARLIGT GODS</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            <Property prop={product.FarligtGodsKlass} text="Klass för farligt gods:" />
                                            <Property prop={product.OfficiellTransportbenamning} text="Officiell transportbenämning:" />
                                            <Property prop={product.FarligtGodsSarbestammelser} text="Farligt gods särbestämmelser:" />
                                            <Property prop={product.FarligtGodsForpackningsgrupp} text="Förpackningsgrupp för farligt gods:" />
                                            <Property prop={product.Transportkategori} text="Transportkategori:" />
                                            <Property prop={product.FarligtGodsKod} text="UN-nummer farligt gods:" />
                                            <Property prop={product.KlassificeringskodFarligtgods} text="Klassificering av farligt gods:" />
                                            <Property prop={product.OspecificeradTransportbenamning} text="Ospecificerad transportbenämning:" />
                                            {product.Varningsetiketter && product.Varningsetiketter.map((varning, i) => {
                                                return (
                                                    <React.Fragment key={`varning${i}`}>
                                                    <Property prop={varning.Varningsetikett} text="Varningsetikett för farligt gods:" />  
                                                    <Property prop={varning.Sekvensnummer} text="Sekvensnummer för varningsetikett:" />  
                                                    </React.Fragment>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            }

                            {(modules['ProductCharacteristics/ChemicalInformation/ChemicalIngredient'] || 
                              modules['GHSDetails/GHSDetail/GHSSignalWordsCode'] ||
                              modules['GHSDetails/GHSDetail/GHSSymbolDescriptionCode']) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">Säkerhet</h3>
                                    {modules['ProductCharacteristics/ChemicalInformation/ChemicalIngredient'] && modules['ProductCharacteristics/ChemicalInformation/ChemicalIngredient'].ValueSets.map((chemicalIngredientValueSet, i) => {
                                        return (
                                            <table className="product-sheet__table product-sheet__table--striped product-sheet__table--column product-sheet__table--33">
                                                <thead>
                                                    <tr>
                                                        <th>Kemiska ingrediensens namn</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {chemicalIngredientValueSet.map(chemicalIngredien => {
                                                        return (
                                                            <tr key={`chemicalIngredien${i}`}>
                                                                <td colSpan="2">{chemicalIngredien.Value}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        );
                                    })}

                                    {modules['GHSDetails/GHSDetail/GHSSignalWordsCode'] && modules['GHSDetails/GHSDetail/GHSSignalWordsCode'].ValueSets.map((GHSSignalWordsCodeValueSet, i) => {
                                        return (
                                            <table className="product-sheet__table product-sheet__table--striped">
                                                <tbody>
                                                    {GHSSignalWordsCodeValueSet.map(GHSSignalWords => {
                                                        return (
                                                            <React.Fragment key={`GHSSignalWords${i}`}>
                                                                <Property prop={GHSSignalWords.Value} text="Signalord enligt GHS:" />
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        );
                                    })}

                                    {modules['GHSDetails/GHSDetail/GHSSymbolDescriptionCode'] && modules['GHSDetails/GHSDetail/GHSSymbolDescriptionCode'].ValueSets.map((GHSSymbolDescriptionCode, i) => {
                                        return (
                                            <table className="product-sheet__table product-sheet__table--striped product-sheet__table--column product-sheet__table--33">
                                                <thead>
                                                    <tr>
                                                        <th>Kemikaliemärkning enligt GHS</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {GHSSymbolDescriptionCode.map(GHSSymbolDescription => {
                                                        return (
                                                            <tr key={`GHSSymbolDescription${i}`}>
                                                                <td colSpan="2">{GHSSymbolDescription.Value}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        );
                                    })}
                                </div>
                            }

                            
                            {(product.Sakerhet && product.Sakerhet.length > 0) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">Säkerhet</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            {product.Sakerhet.map((sakerhet, i) => {
                                                return (
                                                    <React.Fragment key={`sakerhet${i}`}>
                                                        <Property prop={sakerhet.Ingrediens} text="Kemiska ingrediensens namn:" />
                                                        <Property prop={sakerhet.SignalordEnligtGHS} text="Signalord enl. GHS:" />
                                                        <Property prop={sakerhet.KemikaliemarkningGHS} text="Kemikaliemärkning enligt GHS:" />
                                                    </React.Fragment>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            }
        
                            {(product.Faroangivelser && product.Faroangivelser.length > 0) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">FAROANGIVELSE (CLP)</h3>
                                    <table className="product-sheet__table product-sheet__table--striped product-sheet__table--column product-sheet__table--33">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Faroangivelse
                                                </th>
                                                <th>
                                                    Kod
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {product.Faroangivelser.map((faroangivelse, i) => {
                                                return (
                                                    <tr key={`faroangivelse${i}`}>
                                                        <td>{faroangivelse.Text}</td>
                                                        <td>{faroangivelse.Kod}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            }

                            {(product.Skyddsangivelse && product.Skyddsangivelse.length > 0) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">SKYDDSANGIVELSE (CLP)</h3>

                                    <table className="product-sheet__table product-sheet__table--striped product-sheet__table--column product-sheet__table--33">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Skyddsangivelse
                                                </th>
                                                <th>
                                                    Kod
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {product.Skyddsangivelse.map((skydd, i) => {
                                                return (
                                                    <tr key={`skydd${i}`}>
                                                        <td>{skydd.Text}</td>
                                                        <td>{skydd.Kod}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            }
        
                            {(product.Farger && product.Farger.length > 0) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">FÄRG</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            {product.Farger.map((farger, i) => {
                                                return (
                                                    <React.Fragment key={`farger{i}`}>
                                                        <Property prop={farger.Fargkod} text="Färgkod:" />
                                                        <Property prop={farger.Fargsystem} text="Färgsystem:" />
                                                        <Property prop={farger.Fargbeskrivning} text="Färgbeskrivning:" />
                                                    </React.Fragment>
                                                );
                                            })}                 
                                        </tbody>
                                    </table>   
                                </div>
                            }
        
                            {(product.Kravspecifikationer && product.Kravspecifikationer.length > 0) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">Kravspecifikation</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            {product.Kravspecifikationer.map((krav, i) => {
                                                return (
                                                    <React.Fragment key={`krav${i}`}>
                                                        <Property prop={krav.TypAvKravspecifikationText} text="Typ av kravspecifikation:" />
                                                        <Property prop={krav.ReferensTillKravspecifikation} text="Referens till kravspecifikation:" />
                                                        <Property prop={krav.AnsvarigForKravspecifikation} text="Ansvarig för kravspecifikation:" />
                                                        <Property prop={krav.StartdatumForLicensEllerTillstand && format(new Date(krav.StartdatumForLicensEllerTillstand),'yyyy-MM-dd')} text="Starttidpunkt för licens eller tillstånd:" />
                                                        <Property prop={krav.TypAvLicensEllerTillstand} text="Typ av licens eller tillstånd:" />
                                                        {krav.OmfattasAvKravspecifikation !== null &&
                                                            <Property prop={krav.OmfattasAvKravspecifikation ? 'Ja' : 'Nej'} text="Omfattas artikeln av kravspecifikation:" />
                                                        }
                                                        <Property prop={krav.NivaRegleratAmneText} text="Innehållsnivå för reglerat ämne:" />
                                                    </React.Fragment>
                                                );
                                            })}                        
                                        </tbody>
                                    </table>   
                                </div>
                            }
        
                            {product.FarligarDelarBorttagbara &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">ÅTERVINNING</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            <Property prop={product.FarligarDelarBorttagbara} text="Är farliga delar löstagbara:" />
                                        </tbody>
                                    </table>
                                </div>
                            }

                            {(product.Produktegenskaper && product.Produktegenskaper.length > 0) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">PRODUKTEGENSKAPER</h3>
                                    <table className="product-sheet__table product-sheet__table--striped product-sheet__table--column product-sheet__table--33">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Produktegenskap
                                                </th>
                                                <th>
                                                    Värde
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {product.Produktegenskaper.map((item, i) => {
                                                return (
                                                    <tr key={`produktegenskap${i}`}>
                                                        <td>{item.ProduktEgenskapTyp}</td>
                                                        <td>{item.Värde}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            }

                            {(product.T4307_SockelTyp || product.T4291_TypAvLampaText || product.T4293_LjusFlode || product.T4292_FargTemperatur) &&
                                <div className="product-sheet-box__tablecontent">
                                    <h3 className="product-sheet-box__tableheading">LJUSKÄLLOR</h3>
                                    <table className="product-sheet__table product-sheet__table--striped">
                                        <tbody>
                                            <Property prop={product.T4291_TypAvLampaText} text="Typ av lampa:" />
                                            <Property prop={product.T4307_SockelTyp} text="Typ av sockel:" />
                                            <Property prop={product.T4293_LjusFlode && `${product.T4293_LjusFlode_Formatted} ${product.T4293_LjusFlodeEnhet && product.T4293_LjusFlodeEnhet.toLowerCase()}`} text="Ljusflöde:" />
                                            <Property prop={product.T4292_FargTemperatur && `${product.T4292_FargTemperatur_Formatted} ${product.T4292_FargTemperaturEnhetText && product.T4292_FargTemperaturEnhetText.toLowerCase()}`} text="Färgtemperatur:" />
                                        </tbody>
                                    </table>
                                </div>
                            }
        
                        </div>
                    </div>
                </section>
            </>
        );
    }
};

export default Bas;