const getDistinctArticle = (level) => {
    const distinct = [];
    level.forEach(art => {
        if (!distinct.find(article => article.GTIN === art.GTIN)) {
            distinct.push(art);
        }
    });
    return distinct;
}

const consistsOf = (level) => {
    const consists = [];
    level.forEach(l => {
        l.ChildArtikel.forEach(child => {
            consists.push({
                gtin: l.GTIN,
                text: `${child.AntalEnheter} st ${getLevelName(child.Artikeltyp)} ${child.GTIN}`
            });
            
        });
        l.consists = [...consists];
        consists.length = 0;
    });
    
}

const includesIn = (level, includes) => {
    level.forEach(l => {
        const append = includes.filter(include => include.gtin === l.GTIN);
        if (append) {
            l.includes = append;
        }
    });
}

const getLevelName = (level) => {
    switch(level) {
        case 'ArtikelToppniva':
            return 'Toppnivåförpackningar';
        case 'ArtikelMellanniva':
            return 'Mellannivåförpackningar';
        case 'ArtikelBasniva':
            return 'Basnivåförpackningar';
        default:
            return '';
    }
}

const getAllIncludesIn = (level, includes) => {
    level.forEach(l => {
        l.ChildArtikel.forEach(child => {                
            includes.push({
                gtin: child.GTIN,
                text: `${child.AntalEnheter} st ${getLevelName(l.Artikeltyp)} ${l.GTIN}`
            });
        });
    });
}
const move = (from, to, [...a]) => {
    return from === to ? a : (a.splice(to, 0, ...a.splice(from, 1)), a)        
}
const getAllFromLevel = (level, levelName, result, parent = null) => {
    if (level.Artikeltyp === levelName) {
        level.parentGTIN = parent && parent.GTIN;
        level.parentArtikeltyp = parent && parent.Artikeltyp;
        level.parentAntalEnheter = parent && parent.AntalEnheter;
        result.push(level);
    }

    level.ChildArtikel && level.ChildArtikel.length > 0 && level.ChildArtikel.forEach(item => getAllFromLevel(item, levelName, result, level));
}

const returnLevelName = (artikeltyp) => {
    switch (artikeltyp) {
        case 'ArtikelToppniva':
            return '(Topp)';
        case 'ArtikelMellanniva':
            return '(Mellan)';
        case 'ArtikelBasniva':
            return '(Bas)';
    }

    return '';
};

const getArtNr = (flattend) => {
    let artNr = [];
    
    if (flattend.base) {
        flattend.base.forEach(item => {
            if(item.KomplettArtikel && item.KomplettArtikel.BestallningsbarForpackning && item.TillverkarensArtikelnummer) {
                artNr.push({
                    artNr: item.TillverkarensArtikelnummer,
                    artikeltyp: returnLevelName(item.Artikeltyp)
                }); 
            }
        });
    }
    
    if (flattend.middle) {
        flattend.middle.forEach(item => {
            if(item.KomplettArtikel && item.KomplettArtikel.BestallningsbarForpackning && item.TillverkarensArtikelnummer) {
                artNr.push({
                    artNr: item.TillverkarensArtikelnummer,
                    artikeltyp: returnLevelName(item.Artikeltyp)
                }); 
            }
        });
    }
    
    if (flattend.top) {
        flattend.top.forEach(item => {
            if(item.KomplettArtikel && item.KomplettArtikel.BestallningsbarForpackning && item.TillverkarensArtikelnummer) {                
                artNr.push({
                    artNr: item.TillverkarensArtikelnummer,
                    artikeltyp: returnLevelName(item.Artikeltyp)
                }); 
            }
        });
    }

    return artNr;
}

const flattenProduct = (productHierarchy, params) => {
            
    const topLevels = [];
    productHierarchy.forEach(level => {
        getAllFromLevel(level, 'ArtikelToppniva', topLevels);
    });

    const middleLevels = [];
    productHierarchy.forEach(level => {
        getAllFromLevel(level, 'ArtikelMellanniva', middleLevels);
    });
    
    const baseLevelsTemp = [];
    productHierarchy.forEach(level => {
        getAllFromLevel(level, 'ArtikelBasniva', baseLevelsTemp);
    });

    const indexOfCurrentGTIN = baseLevelsTemp.findIndex((item) => item.GTIN === params.slug);
    const baseLevels = move(indexOfCurrentGTIN, 0, baseLevelsTemp);

    //Append Includes In
    const includes =  [];
    getAllIncludesIn(baseLevels, includes);
    getAllIncludesIn(middleLevels, includes);
    getAllIncludesIn(topLevels, includes);

    includesIn(topLevels, includes);
    includesIn(middleLevels, includes);
    includesIn(baseLevels, includes);
    
    //Append Consists Of
    consistsOf(baseLevels);
    consistsOf(middleLevels);
    consistsOf(topLevels);

    //hierarchy
    const flattendProductHierarchy = {
        top: getDistinctArticle(topLevels),
        middle: getDistinctArticle(middleLevels),
        base: getDistinctArticle(baseLevels),
    }

    return flattendProductHierarchy;
}

export {
    flattenProduct,
    getArtNr
};